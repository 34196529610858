.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsBold';
  src: url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#availabilityEditCalendar .MuiBadge-anchorOriginTopRightRectangle {
  top: 22px;
  right: 50%;
}

#availabilityEditCalendar .MuiBadge-dot {
  height: 4px;
  min-width: 4px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drawer div {
  z-index: 500;
}

.refuseHost.MuiIconButton-colorPrimary {
  color: darkorange;
}

.approveHost.MuiIconButton-colorPrimary,
.unlockPublication.MuiIconButton-colorPrimary {
  color: green;
}

.deactivateHost.MuiIconButton-colorPrimary,
.lockPublication.MuiIconButton-colorPrimary {
  color: red;
}

.payBooking.MuiIconButton-colorPrimary {
  color: darkblue;
}

.refuseCompany.MuiIconButton-colorPrimary {
  color: darkorange;
}

.approveCompany.MuiIconButton-colorPrimary,
.unlockPublication.MuiIconButton-colorPrimary {
  color: green;
}

.deactivateCompany.MuiIconButton-colorPrimary,
.lockPublication.MuiIconButton-colorPrimary {
  color: red;
}

.o_day-picker .i_day-picker-row div.o_selected-day {
  background: #ffffff! important;
}

.o_day-picker .i_day-picker-header {
  display: flex;
  color: black;
  font-weight: 900;
  text-transform: capitalize;
  border-bottom: 1px solid #eaecec;
}

.o_day-picker .e_day-picker-buttons {
  justify-content: space-between;
}

.o_day-picker {
  min-width: 270px;
}

.o_selected-day, .o_selected-day * {
  pointer-events: none;
}

.diagonal-container {
  background: linear-gradient(to bottom right, #fff calc(50% - 2px), #aaa, #fff calc(50% + 2px) )! important;
}

.servicesPriceInput input.MuiOutlinedInput-input{
  padding: 5px;
  text-align: center;
}

p:first-letter {
  text-transform:capitalize;
}


.bank-card {
  display: flex;
  justify-content: space-evenly;
  border-radius: 7px;
  width: 250px;
  height: 100px;
  box-shadow: 0 3px 4px 0 #00d1bf;
  /* background: rgb(126,45,135); */

  /* background: linear-gradient(18deg, rgba(126, 45, 135, 1) 25%, rgba(0, 209, 191, 1) 74%); */
  color: #efefef;
  align-items: center;
}

.bank-card.selectable:hover {
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bank-card-text {
  color: #000000;
  font-size: smaller;
  font-weight: 400;
}

/*
.MuiDropzonePreviewList-image {
  height: unset ! important;
}

.MuiDropzoneArea-root {
  min-height: unset ! important;
} */


.MuiDropzoneArea-root {
  min-height: 102% ! important;
  min-width: 102% ! important;
  border: none ! important;
  background-color: #F0F0F0 ! important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiDropzoneArea-icon {
  display: none !important;
}

 .MuiDropzonePreviewList-root.MuiGrid-container {
  width: 100% ! important;
  margin: 0;
  padding: 15px;
}


/* react month picker overwrite (space creation/edit)   */

.monthCalendarContainerPicker {
  width: 100px !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
}

.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-overlay {
  display: none !important;
}

.monthCalendarContainerSpace .rmp-container,
.monthCalendarContainerSpace .rmp-popup,
.monthCalendarContainerSpace .rmp-pad {
  width: 100% !important;
}

.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup {
  position: static ! important;
}


.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup.light {
  border-top: none !important;
  box-shadow: none !important;
  background-color: none !important;
  border: none !important;
}

.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup.light .rmp-pad li.active,
.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: #d3d3d3 !important;
  color: black !important;
  background: transparent !important;
}

.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup.light .rmp-pad li.disable,
.month-picker>.rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
  background: white !important;
}

.monthCalendarContainerSpace .month-picker>.rmp-container .rmp-popup .rmp-pad li.multiple {
  background: linear-gradient(to bottom right, #fff calc(50% - 2px), #aaa, #fff calc(50% + 2px)) ! important;
}

@media screen and (max-width: 767px) {
  .monthCalendarContainerSpace .month-picker>.rmp-container {
    position: relative ! important;
    top: 16px ! important;
  }
}

.monthCalendarContainerSpace .rmp-btn.multiple {
  border: 1px solid grey;
}




.monthCalendarContainerDev2,
.monthCalendarContainerDev {
  width: 300px !important;
  position: fixed;
  z-index: 1300;
}

.monthCalendarContainerDev2 .rmp-btn {
  border-radius: 15px !important;
  line-height: 2.5rem !important;
  width: 4rem !important;
  margin: 3px !important;
}

.monthCalendarContainerDev2 .rmp-btn.select,
.monthCalendarContainerDev2 .rmp-btn.active  {
  background-color: #9d0ace !important;
}

.monthCalendarContainerDev2 .rmp-btn.select  {
  opacity: 0.7;
  color: white !important;
}

.monthCalendarContainerDev2 *,
.monthCalendarContainerDev * {
  text-align: center ! important;
}

.monthCalendarContainerDev .rmp-popup.show,
.monthCalendarContainerDev2 .rmp-popup.show {
  margin-top: 40px ! important;
}

/* RUG  */

.onboardingForm .rug-card .rug-card-progress .__progress-cricle {
  stroke: #9d0ace ! important;
}
.onboardingForm .rug-card {
  background-color: #3d485242;
}

.onboardingForm .rug-card-progress-count {
  text-shadow: 3px 3px 3px #000000;
}

.onboardingForm .rug .rug-items.__card {
  min-height: 0px;
  padding-top: 8px;
  scale: 96% 100%;
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
}

.onboardingForm .rug-items.__card.__sorting>div:nth-child(1)>div {
  border: 3px solid #9900CC;
  border-radius: 15px;
}

/* google autocomplete on modal */
.pac-container {
  z-index: 9999;
}
